.root {
     width: 100%;
     height: calc(100vh - 120px);
     min-height: fit-content;
     // background-color: #123048;
     margin-top: 120px;

     .container {
          width: calc(100% - 40px);
          margin-left: 20px;
          min-height: fit-content;
          padding-bottom: 80px;

          .subContainer {
               position: relative;
               min-height: 415px;
               height: fit-content;
               margin-top: 30px;

               &.mobile {
                    min-height: 0px;
                    margin-top: 0px;
                    height: fit-content;
               }

               .img {
                    position: absolute;
                    top: 0;
                    width: 335px;
                    height: 407px;
                    background-image: url('../../imgs/about/3.jpg');
                    background-size: 100% auto;
                    background-repeat: no-repeat;

                    &.mobile {
                         position: relative;
                    }

                    &.pc_right {
                         margin-left: calc(100% - 335px);
                    }
               }

               .t1 {
                    font-size: 32px;
                    font-weight: bold;
                    color: #000000;
                    white-space: break-spaces;

                    &.mobile {
                         margin-left: 0;
                    }

                    &.pc_right {
                         margin-left: 350px;
                         width: calc(100% - 350px);
                    }

                    &.pc_left {
                         margin-left: 0px;
                         width: calc(100% - 350px);
                    }
               }

               .t2 {
                    font-size: 28px;
                    color: #000000;
                    font-weight: bold;
                    white-space: break-spaces;

                    &.mobile {
                         margin-left: 0;
                    }

                    &.pc_right {
                         margin-left: 350px;
                         width: calc(100% - 350px);
                    }

                    &.pc_left {
                         margin-left: 0px;
                         width: calc(100% - 350px);
                    }
               }

               .t3 {
                    font-size: 16px;
                    color: #000000;
                    white-space: break-spaces;
                    font-weight: bold;

                    &.mobile {
                         margin-left: 0;
                    }

                    &.pc_right {
                         margin-left: 350px;
                         width: calc(100% - 350px);
                    }

                    &.pc_left {
                         margin-left: 0px;
                         width: calc(100% - 350px);
                    }
               }

               .txt {
                    font-size: 16px;
                    color: #000000;
                    white-space: break-spaces;

                    &.mobile {
                         margin-left: 0;
                    }

                    &.pc_right {
                         margin-left: 350px;
                         width: calc(100% - 350px);
                    }

                    &.pc_left {
                         margin-left: 0px;
                         width: calc(100% - 350px);
                    }
               }
          }
     }
}