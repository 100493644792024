.root {
     width: 100%;
     height: calc(100vh - 120px);
     min-height: fit-content;
     // background-color: #123048;
     margin-top: 120px;

     .container {
          width: calc(100% - 40px);
          margin-left: 20px;
          
          .img1{
               width: 335px;
               height: 335px;
               margin-left: calc(50% - 168px);
               background-image: url('../../imgs/member/1.jpg');
               background-size: 100% auto;
          }

          .t1 {
               font-size: 32px;
               font-weight: bold;
               color: #030303;
               white-space: break-spaces;
          }
     
          .t2 {
               font-size: 28px;
               font-weight: bold;
               color: #000000;
               white-space: break-spaces;
          }

          .t3 {
               font-size: 16px;
               font-weight: bold;
               color: #000000;
               white-space: break-spaces;
          }
     
          .txt {
               font-size: 16px;
               color: #000000;
               white-space: break-spaces;
          }
     }
}