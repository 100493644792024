.root {
    width: 100%;
    height: 100vh;
    min-height: fit-content;

    .main_bg {
        position: relative;
        background-image: url('../../../public/res/title.jpg');
        background-size: 100% auto;
        width: 100%;
        aspect-ratio: 1.5625;

        .slogan {
            position: relative;
            padding-top: 390px;
            padding-left: 60px;
            font-size: 80px;
            text-align: center;
            font-weight: bold;
            color: #000000;

            &.mobile {
                font-size: 24px;
                padding-left: 36px;
                padding-top: 150px;
            }
        }
    }



    .container {
        position: relative;
        width: calc(100% - 40px);
        margin-left: 20px;

        .t1 {
            font-size: 32px;
            font-weight: bold;
            color: #000000;
            white-space: break-spaces;
        }

        .t2 {
            font-size: 28px;
            color: #000000;
            white-space: break-spaces;
        }

        .txt {
            font-size: 18px;
            color: #000000;
            white-space: break-spaces;
        }
    }
}