.root {
  width: 100%;
  max-width: calc(100vh*1.4);

  overflow-y: scroll;
  overflow-x: hidden;

  background-color: #ffffff;

  &::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: rgba(19, 23, 55, 0);
  }

  &::-webkit-scrollbar {
    width: 0px;
    background-color: rgba(19, 23, 55, 0);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: rgba(255, 255, 255, 0.7);
  }

  &:hover::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: rgba(255, 255, 255, 0.7);
  }
}