.root {
    position: relative;
    width: 100%;
    height: fit-content;
    padding-top: 60px;

    .links {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;

        .img {
            position: relative;
            width: 200px;
            max-width: 45%;
            height: 100px;
            margin-left: 5px;
            margin-right: 5px;
            background-size: 100% auto;
            background-repeat: no-repeat;
        }
    }
}