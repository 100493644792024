.root {
     width: 100%;
     height: calc(100vh - 120px);
     min-height: fit-content;
     // background-color: #123048;
     margin-top: 120px;

     .container {
          width: calc(100% - 100px);
          margin-left: 50px;

          .carousel {
               width: 100%;
               height: 200px;
               .book {
                    width: 139px;
                    height: 200px;
                    margin-left: 10px;
                    background-size: 100% auto;
               }
          }


          .t1 {
               font-size: 28px;
               font-weight: bold;
               color: #000000;
               white-space: break-spaces;
          }

          .t2 {
               font-size: 28px;
               color: #000000;
               font-weight: bold;
               white-space: break-spaces;
          }

          .txt {
               font-size: 12px;
               color: #000000;
               white-space: break-spaces;
          }
     }
}