.root {
    position: fixed;
    width: 100%;
    max-width: calc(100vh*1.4);
    height: 110px;
    z-index: 10000;

    .title_bg {
        position: absolute;
        background-image: url('../../public/res/title.jpg');
        background-size: 100% auto;
        width: 100%;
        height: 110px;
    }

    .logo_container {
        position: absolute;
        width: 100%;
        height: max-content;

        .logo {
            position: absolute;
            width: 80px;
            height: 80px;
            margin-left: 20px;
            margin-top: 20px;
            background-image: url('../../public/res/logo.png');
            background-size: 100% auto;
            cursor: pointer;
        }

        .logo_text {
            position: absolute;
            margin-left: 120px;
            margin-top: 35px;
            font-size: 20px;
            font-weight: bold;
            color: #000000;
        }

        .logo_text_en {
            position: absolute;
            margin-left: 120px;
            margin-top: 70px;
            font-size: 12px;
            color: #000000;
        }
    }


    .container {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        width: calc(100% - 100px);
        margin-top: 32px;
        margin-left: 110px;

        .btn {
            position: relative;
            align-items: center;
            justify-content: center;
            display: flex;
            width: fit-content;
            height: 50px;
            margin-right: 10px;
            font-size: 16px;
            color: #0a0a0a;
            cursor: pointer;
            font-weight: bold;

            &.btn_focus {
                background-color: #6a69694d;
            }
        }
    }
}